"use strict";

// Class Definition
var KTLogin = function() {
	var _login;

	var _showForm = function(form) {
			var cls = 'login-' + form + '-on';
			var form = 'kt_login_' + form + '_form';

			_login.removeClass('login-forgot-on');
			_login.removeClass('login-signin-on');
			_login.removeClass('login-signup-on');

			_login.addClass(cls);

			KTUtil.animateClass(KTUtil.getById(form), 'animate__animated animate__backInUp');
	}

	var _handleSignInForm = function() {
			var validation;

			// Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
			validation = FormValidation.formValidation(
		KTUtil.getById('kt_login_signin_form'),
		{
			fields: {
				username: {
					validators: {
						notEmpty: {
							message: 'Username is required'
						}
					}
				},
				password: {
					validators: {
						notEmpty: {
							message: 'Password is required'
						}
					}
				}
			},
			plugins: {
									trigger: new FormValidation.plugins.Trigger(),
									submitButton: new FormValidation.plugins.SubmitButton(),
									//defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
				bootstrap: new FormValidation.plugins.Bootstrap()
			}
		}
	);

			$('#kt_login_signin_submit').on('click', function (e) {
					e.preventDefault();

					validation.validate().then(function(status) {
					if (status == 'Valid') {
									swal.fire({
									text: "Todo bien! Enviar forma",
									icon: "success",
									buttonsStyling: false,
									confirmButtonText: "Ok",
											customClass: {
							confirmButton: "btn font-weight-bold btn-light-primary"
						}
							}).then(function() {
					KTUtil.scrollTop();
				});
			} else {
				swal.fire({
									text: "Lo sentimos, detectamos algunos errores, por favor intenta de nuevo.",
									icon: "error",
									buttonsStyling: false,
									confirmButtonText: "Ok",
											customClass: {
							confirmButton: "btn font-weight-bold btn-light-primary"
						}
							}).then(function() {
					KTUtil.scrollTop();
				});
			}
			});
			});

			// Handle forgot button
			$('#kt_login_forgot').on('click', function (e) {
					e.preventDefault();
					_showForm('forgot');
			});

			// Handle signup
			$('#kt_login_signup').on('click', function (e) {
					e.preventDefault();
					_showForm('signup');
			});
	}

	var _handleSignUpForm = function(e) {
			var validation;
			var form = KTUtil.getById('kt_login_signup_form');

			// Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
			validation = FormValidation.formValidation(
		form,
		{
			fields: {
				fullname: {
					validators: {
						notEmpty: {
							message: 'El nombre de usuario es requerido'
						}
					}
				},
				email: {
											validators: {
						notEmpty: {
							message: 'El correo es requerido'
						},
													emailAddress: {
							message: 'El correo electrónico no es válido'
						}
					}
				},
									password: {
											validators: {
													notEmpty: {
															message: 'El password es requerido'
													}
											}
									},
									cpassword: {
											validators: {
													notEmpty: {
															message: 'Se requiere la confirmación del password'
													},
													identical: {
															compare: function() {
																	return form.querySelector('[name="password"]').value;
															},
															message: 'El password y la confirmación no coinciden'
													}
											}
									},
									agree: {
											validators: {
													notEmpty: {
															message: 'Debes aceptar los términos y condiciones'
													}
											}
									},
			},
			plugins: {
				trigger: new FormValidation.plugins.Trigger(),
				bootstrap: new FormValidation.plugins.Bootstrap()
			}
		}
	);

			$('#kt_login_signup_submit').on('click', function (e) {
					e.preventDefault();

					validation.validate().then(function(status) {
					if (status == 'Valid') {
									swal.fire({
									text: "Todo bien! Puedes enviar la forma",
									icon: "success",
									buttonsStyling: false,
									confirmButtonText: "Ok!",
											customClass: {
							confirmButton: "btn font-weight-bold btn-light-primary"
						}
							}).then(function() {
					KTUtil.scrollTop();
				});
			} else {
				swal.fire({
									text: "Lo sentimos, detectamos algunos errores, intenta de nuevo.",
									icon: "error",
									buttonsStyling: false,
									confirmButtonText: "Ok!",
											customClass: {
							confirmButton: "btn font-weight-bold btn-light-primary"
						}
							}).then(function() {
					KTUtil.scrollTop();
				});
			}
			});
			});

			// Handle cancel button
			$('#kt_login_signup_cancel').on('click', function (e) {
					e.preventDefault();

					_showForm('signin');
			});
	}

	var _handleForgotForm = function(e) {
			var validation;

			// Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
			validation = FormValidation.formValidation(
		KTUtil.getById('kt_login_forgot_form'),
		{
			fields: {
				email: {
					validators: {
						notEmpty: {
							message: 'Correo es requerido'
						},
													emailAddress: {
							message: 'No es un correo válido'
						}
					}
				}
			},
			plugins: {
				trigger: new FormValidation.plugins.Trigger(),
				bootstrap: new FormValidation.plugins.Bootstrap()
			}
		}
	);

			// Handle submit button
			$('#kt_login_forgot_submit').on('click', function (e) {
					e.preventDefault();

					validation.validate().then(function(status) {
					if (status == 'Valid') {
									// Submit form
									KTUtil.scrollTop();
			} else {
				swal.fire({
									text: "Lo sentimos, detectamos algunos errores, intenta de nuevo.",
									icon: "error",
									buttonsStyling: false,
									confirmButtonText: "Ok",
											customClass: {
							confirmButton: "btn font-weight-bold btn-light-primary"
						}
							}).then(function() {
					KTUtil.scrollTop();
				});
			}
			});
			});

			// Handle cancel button
			$('#kt_login_forgot_cancel').on('click', function (e) {
					e.preventDefault();

					_showForm('signin');
			});
	}

	// Public Functions
	return {
			// public functions
			init: function() {
					_login = $('#kt_login');

					_handleSignInForm();
					_handleSignUpForm();
					_handleForgotForm();
			}
	};
}();

// Class Initialization
jQuery(document).ready(function() {
	KTLogin.init();
});


